<template>    
    <v-col cols="4" class="pa-0">
        <v-card-title class="text-h5 primary--text justify-center font-weight-bold">Stories</v-card-title>
        <v-card  tile elevation="5" :loading="storyLoading" class="height-calc">
            <template slot="progress">
                <v-progress-linear
                color="secondary"
                height="4"
                indeterminate
                ></v-progress-linear>
            </template>
            <div class="primary py-3 px-4">
                <v-text-field hide-details="auto" class="primary--text" placeholder="Search" dense solo
                    prepend-inner-icon="mdi-magnify" v-model="search" @input="searchStory"/>
            </div>
            <vue-perfect-scrollbar @ps-scroll-y="storyScroll" class="story-container" :settings="{'maxScrollbarLength': 60}">
                <template v-if="storyLoading">
                    <v-skeleton-loader v-for="(item, i) in [1,2,3,4]" :key="i" class="mb-0"
                        v-bind="{class: 'producer-story-loader'}"
                        type="list-item-two-line, actions, divider"
                    ></v-skeleton-loader>
                </template>
                <v-row class="ma-0 pa-8 pt-16 text-h6 secondary--text" aling="center" justify="center" v-else-if="!storyLoading && search && storyLists.length === 0">
                    No Data Found!.
                </v-row>
                <v-card v-else tile  v-for="(item, i) in storyLists" :key="i">
                    <div class="pa-5 pb-0">
                        <div class="pa-0 pb-1 d-block story-para text-subtitle-2" v-html="item.title"></div>
                        <v-subheader v-if="item.story_details" class="pa-0 text-subtitle-1 h-auto">{{ item.story_details }}</v-subheader>
                    </div>
                    <v-row align="center" justify="center" justify-lg="end" class="ma-0 background pr-5">
                        <v-btn text class="text-capitalize text-subtitle-2" color="info"
                            @click="openSlackHistory(item)">
                            <v-icon dark class="mr-1" color="info">mdi-message-text</v-icon>
                            Slack
                        </v-btn>
                        <v-btn text class="text-capitalize text-subtitle-2" color="info" :disabled="item.metionLoading"
                            @click="setStoryMention(item.id, i)">
                            <v-icon dark class="mr-1" color="info">mdi-account-voice</v-icon>
                            Mention
                        </v-btn>
                    </v-row>
                </v-card>
            </vue-perfect-scrollbar>
        </v-card>
        <SlackHistoryModal v-if="slackModal" :slackModal="slackModal" @closeModal="slackModal=false"/>
    </v-col>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';


export default {
    components: {
        SlackHistoryModal: () => import("@/pages/active-campaign/producer/components/story-component/SlackHistoryModal"),
    },
    data: (vm) => ({
        slackModal: false,
        search: '',
        page: 1,
        storyLists: [],
        storyLoading: false,
        searchTimeId: null
    }),
    created() {
        this.storyLoading = true;
        this.getStoryList(`page=1&on_air=1`).then(resp => {
            this.storyLists = resp;
            this.storyLoading = false;
        }).catch(err => this.storyLoading = false);
    },
    mounted() {
        this.wsStoryUpdate();
    },
    methods: {
        ...mapActions(['getStoryList', 'getSlackHistory', 'updateStoryMention']),

        storyScroll(evt) {
            if (evt.target.scrollTop + evt.target.clientHeight >= evt.target.scrollHeight) {
                this.page++;
                let endPoint = `page=${this.page}&on_air=1`;

                if(this.search){
                  endPoint += `&q=${this.search}`;
                }

                this.getStoryList(endPoint).then(response => {
                    if (response.length) response.forEach(item => this.storyLists.push(item));
                }).catch(err => {});
            }
        },
        searchStory(search) {
            if (this.searchTimeId) {
                clearTimeout(this.searchTimeId);
                this.searchTimeId = false;
            }
            const self = this;
            this.searchTimeId = setTimeout(async () => {
                self.$store.dispatch('cancelPendingRequests');
                self.storyLoading = true;
                self.page = 1;
                const endPoint = search ? `page=1&q=${search}` : 'page=1';
                this.getStoryList(endPoint).then(response => {
                    self.storyLists = response.length ? response : [];
                    self.storyLoading = false;
                }).catch(err => self.storyLoading = false);
                self.searchTimeId = false;
            }, 500);
        },
        openSlackHistory(item) {
            const reqData = {
                channel: item.slack_channel,
                ts: item.slack_ts
            }
            this.getSlackHistory(reqData);
            this.slackModal = true;
        },
        setStoryMention(storyId, itemIndex) {
            this.storyLists[itemIndex].metionLoading = true;
            this.updateStoryMention(storyId).then((res) => {
                this.storyLists[itemIndex].metionLoading = false;
            }).catch((err) => {
                item.metionLoading = false;
                this.storyLists.slice(itemIndex, 1, item);
            });
        },
        wsStoryUpdate() {
            Echo.channel('story').listen('.updated',(storyItem) => {
                const updatedIndex = this.storyLists.findIndex(item => item.id === storyItem.id);
                if (updatedIndex !== -1) this.storyLists.splice(updatedIndex, 1, storyItem);
                else if (storyItem.on_air == 1) this.storyLists.unshift(storyItem);
            });
        }
    },
    beforeDestroy() {
        Echo.leaveChannel('story');
    }
}
</script>

<style lang="scss">
.story-container {
  position: relative;
  margin: auto;
  height: 737px;
}
.height-calc {
    height: calc(100% - 110px);
}
</style>